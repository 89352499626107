/* eslint-disable */

import React from 'react'

class SeasonalWorkView extends React.Component {

  render() {

    return (
      <span>
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com"  />
<link href="https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@1,700&display=swap" rel="stylesheet"></link>
        <span className="af-view">
          <div>
            <div className="af-class-section af-class-header-seasonal-work w-clearfix af-class-wf-section">

              <div className="af-class-div-block-5">
                <h1 className="af-class-heading-12-seasonal">Work with us</h1>
                <h1 className="af-class-heading-13-_seasonal">Seasonal Work in Central Otago</h1>
              </div><img src="images/45south-tick-text.png" alt="picture" className="af-class-image-12" />
            </div>
            <div className="af-class-section-2-seasonal af-class-wf-section">
              <div className="w-row">
                <div className="af-class-column-15 w-col w-col-6 w-col-stack">
                  <h2 className="af-class-heading-19-copy">Cherry picking in Cromwell - the best working holiday you'll ever have!</h2>
                </div>
                <div className="af-class-column-18 w-col w-col-6 w-col-stack">
                  <p className="af-class-paragraph-13">The Cromwell cherry harvest starts in early December and continues to the end of January. 45 South Cherries is looking for keen, motivated orchard workers to help us pick and pack our famous cherries for happy customers all across the globe.</p>
                </div>
              </div>
              <div className="af-class-columns-10 w-row">
                <div className="w-col w-col-6"><img src="images/DSC03863-1.png" sizes="(max-width: 479px) 89vw, (max-width: 603px) 87vw, (max-width: 767px) 525px, (max-width: 991px) 45vw, 34vw" srcSet="images/DSC03863-1-p-500.png 500w, images/DSC03863-1.png 525w" alt="picture" />
                  <h3 className="af-class-heading-31">Cherry Picking</h3>
                  <p className="af-class-paragraph-14">If you love outdoor work, sign up for cherry picking and soak up the sunshine! <br /><br />Pickers are paid per hour with a production bonus incentive offered, so the more you pick, the more you're paid! Additionally, a loyalty bonus is paid if you stay for the whole season. Pickers start at approx 6am and work through until 2.30pm. Fortunately during summer hours, the Cromwell sun shines through until around 10pm, meaning you’ll get to enjoy beautiful Central Otago afternoons. Cherry picking is 7 days a week, but we don’t pick in wet weather.<br /><br />Cherry picking suits those with a good level of fitness, a great attitude and those looking for rewarding outdoor work.</p>
                </div>
                <div className="w-col w-col-6"><img src="images/DSC04657-1.png" sizes="(max-width: 479px) 89vw, (max-width: 603px) 87vw, (max-width: 767px) 525px, (max-width: 991px) 45vw, 34vw" srcSet="images/DSC04657-1-p-500.png 500w, images/DSC04657-1.png 525w" alt="picture" />
                  <h3 className="af-class-heading-31">Packhouse Staff</h3>
                  <p className="af-class-paragraph-14">Do you have excellent attention to detail? Join our packhouse team! <br /><br />Packhouse work is great for those who prefer the indoors. You’ll be responsible for sorting, assembling and packing cherry boxes for the local and export market. Depending on the volume of fruit, packhouse hours are usually 7am - 6pm. The packhouse temperature is a little cooler than outside, so you’ll need to sport a couple of layers of clothing. The packhouse is a great team environment!<br /><br />Fortunately during summer hours, the Cromwell sun shines through until around 10pm, meaning you’ll get to enjoy beautiful Central Otago evenings.<br /></p>
                </div>
              </div>
              <div className="w-row">
                <div className="w-col w-col-6">
                  <h2 className="af-class-heading-19-copy-copy">Earn great money whilst enjoying the long, hot summer days of Central Otago.</h2>
                </div>
                <div className="w-col w-col-6">
                  <p className="af-class-paragraph-13"> Mix and mingle in the great seasonal festivities around Cromwell, Queenstown, and Wanaka.</p>
                </div>
              </div>
            </div>
            {/* <span>
            <span className="af-view">
            <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
            <div className="af-class-section-7 af-class-wf-section">
                  <div className="af-class-container-4 w-container">
                   <h2 className="af-class-season_rec_header">Applications for the current season are opening soon!</h2>
                    <div className="af-class-season_apply_blurb">We're planning to open applications in the next 2 weeks, so keep an eye on the website.</div>
                    <div className="af-class-div-block-8 w-clearfix">
                      <a  href="mailto:employment@45s.co.nz?subject=Employment%20Enquiry" target="_blank" className="af-class-seasonal_apply_button_1 w-button">Email Us</a>
                    </div>
                  </div>
                </div>
            </span>
          </span> */}
            {/* <div className="af-class-section-7 af-class-wf-section">
              <div className="af-class-container-4 w-container">
                <h2 className="af-class-season_rec_header">Thanks all for the great season! Job applications are now closed.</h2>
                <div className="af-class-season_apply_blurb">If you’re interested in being part of our team for the next season, check here around August 2024.</div>
              </div>
            </div> */}
                        <div className="af-class-section-7 af-class-wf-section">
              <div className="af-class-container-4 w-container">
                <h2 className="af-class-season_rec_header">Applications for the current season are open!</h2>
                <div className="af-class-season_apply_blurb">If you’re interested in being part of our team for this coming season, apply now.</div>
                <div className="af-class-season_apply_blurb"></div>
                <div className="af-class-div-block-8 w-clearfix">
                  <a href="apply" target="_blank" className="af-class-seasonal_apply_button_1 w-button">APPLY</a>
                </div>
              </div>
            </div>
            <div id="accommodation" className="af-class-section-8 af-class-wf-section">
              <h2 className="af-class-heading-19">Accommodation Options</h2>
              <div className="af-class-text-block-13-copy">Choose from a range of accommodation options! When applying, select your desired accommodation and we’ll be in touch.</div>
              <div className="af-class-columns-10 w-row">
                <div className="w-col w-col-6"><img src="images/webp/Image-from-iOS-16-1.webp" sizes="(max-width: 479px) 89vw, (max-width: 603px) 87vw, (max-width: 767px) 525px, (max-width: 991px) 45vw, 34vw" srcSet="images/Image-from-iOS-16-1-p-500.png 500w, images/Image-from-iOS-16-1.png 525w" alt="picture" />
                  <h3 className="af-class-heading-31">The Cabins</h3>
                  <p className="af-class-paragraph-14">4 person cabins located onsite. A cool little community, all you’ll need is a duvet! Cooking facilities and utensils provided, showers/toilets. Wifi is available at most if not all of the cabins. <br /><br /><strong>Cabins range from $120 - $190 per week, depending on the style</strong></p>
                </div>
                <div className="w-col w-col-6"><img src="images/campcherrysmall.webp" sizes="(max-width: 479px) 89vw, (max-width: 605px) 87vw, (max-width: 767px) 527px, (max-width: 991px) 45vw, 34vw" srcSet="images/campcherrysmall.webp 500w, images/campcherrysmall.webp 527w" alt="picture" />
                  <h3 className="af-class-heading-31">Camp Cherry</h3>
                  <p className="af-class-paragraph-14">Onsite camping/van parking with your fellow pickers and packers! Facilities provided for dish washing, showers, bathrooms. BYO tent/van, stove tops &amp; utensils. Enjoy the no-frills, low cost accommodation approach and stay onsite for the season. Wifi is available through access points set up around the camp.<br /><br /><strong>Campsites range from $70 - $100 per week</strong><br /></p>
                </div>
              </div>
              {/* <div className="af-class-columns-10 w-row">
                <div className="w-col w-col-6"><img src="images/webp/download-2-1.webp" sizes="(max-width: 479px) 89vw, (max-width: 603px) 87vw, (max-width: 767px) 525px, (max-width: 991px) 45vw, 34vw" srcSet="images/webp/download-2-1-p-500.webp 500w, images/download-2-1.webp 525w" alt="picture" />
                  <h3 className="af-class-heading-31">The Chalets</h3>
                  <p className="af-class-paragraph-14">Backpacker style local accommodation located in Cromwell. Cooking facilities. No frills, no worries! Bond of $160 (returned at end of season if all OK).<br /><br />Free transport to and from work in our cherry vans.<br /><br /><strong> $140 per week, per person (shared, up to 6 people)</strong><br /></p>
                </div>
              </div> */}
              <div className="af-class-div-block-14">
                <a href="https://45south.montageonline.co.nz/public/process/new/applicant" target="_blank" className="af-class-button-3-copy w-button">Apply Now</a>
              </div>
            </div>
            <div className="af-class-section-10 af-class-wf-section"><img src="images/wepb/Group-18.webp" sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 100vw" srcSet="images/Group-18-p-500.png 500w, images/Group-18-p-800.png 800w, images/webp/Group-18-p-1080.webp 1080w, images/webp/Group-18.webp 1440w" alt="picture" className="af-class-image-33" /></div>
            <div className="af-class-section-12 af-class-wf-section">
              <div className="af-class-columns-11 w-row">
                <div className="af-class-column-37 w-col w-col-6">
                  <h2 className="af-class-heading-19-copy-copy">Travelling around NZ? Get sorted for the season.</h2>
                </div>
                <div className="af-class-column-38 w-col w-col-6">
                  <p className="af-class-paragraph-13"><strong>Foreign Nationals</strong><br />Foreign nationals must have a valid working holiday visa or work permit, a New Zealand tax number, and a local bank account.<br /><br />For further information on work permits visit <a href="http://www.immigration.govt.nz" target="_blank">www.immigration.govt.nz</a>. For further information on tax visit <a href="http://www.ird.govt.nz" target="_blank">www.ird.govt.nz</a><br /><br /><strong>Other Employment Enquiries</strong><br />If you wish to extend your stay in Central Otago, visit Seasonal Solutions for more employment opportunities <a href="http://www.ssco.co.nz" target="_blank">www.ssco.co.nz</a>.</p>
                </div>
              </div>
              <div className="af-class-div-block-10">
                <div className="af-class-div-block-11 af-class-seasonal-work-cta w-clearfix">
                  <div>
                    <h1 className="af-class-heading-27">Get in touch</h1>
                  </div>
                  <a href="contact" className="af-class-contact_us_btn_2 w-button">CONTACT</a><img src="images/we-look-forward-to-hearing-from-you.png" alt="picture" className="af-class-footer_image_2" />
                </div>
              </div>
            </div>
            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  }
}

export default SeasonalWorkView

/* eslint-enable */