
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/45-south-take-2.webflow.css'
import './styles/css/normalize.css'
import './styles/css/webflow.css'
import Header from './views/Header'
import Footer from './views/Footer'
import FooterMobile from './views/FooterMobile'
import { Outlet } from "react-router-dom";
import { useState } from 'react';


function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 790;
 
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    if (window.location.hostname === 'sicrop.co.nz') {
      window.location.replace('https://45s.co.nz/seasonalwork');
    }
  
    

  if (width < breakpoint) {
    return (
      <div className="App">
              
  <Header></Header>
  <Outlet />
  <FooterMobile />
  
      </div>
    );

  } else {
    return (
      <div className="App">

  <Header></Header>
  <Outlet />
  <Footer />
  
      </div>
    );
  }

}

export default App;
