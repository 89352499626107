/* eslint-disable */

import React, { useState } from 'react'


function MapView() {

  const [show,setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
      <span>
        <span className="af-view">
        <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
          <div>
            <div className="af-class-section af-class-header-map af-class-wf-section">
              <div className="af-class-div-block-5">
                <h1 className="af-class-heading-12-seasonal">Where to go</h1>
                <h1 className="af-class-heading-13-_seasonal">Maps and Location</h1>
              </div>
            </div>
            <div className="af-class-section-2 af-class-wf-section">
              <div className="af-class-div-block-6">
                <div className="af-class-div-block-7">
                </div>
              </div>
              <h2 className="af-class-heading_about_top">Our Traffic Flow Plan</h2>
              <p className="af-class-paragraph-2">To ensure the safety of all of our staff, we've set up a plan to limit the number of vehicles entering the high traffic areas around the packhouse. The above map outlines the path that all vehicles entering 45 South should follow.<br /></p>
            </div>
            <div className="af-class-section_four1 af-class-cta-about-page af-class-wf-section">
              <div className="af-class-div-block-3-copy">
                <div className="af-class-div-block-4-copy af-class-cta-about">
                  <div>
                    <h2 className="af-class-heading-10">Have a Question?<br />Let's Chat</h2>
                    <a href="contact" className="af-class-button-2 w-button">CONTACT&nbsp;US</a><img src="images/we-look-forward-to-hearing-from-you.png" alt="picture" className="af-class-image-7" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-embed w-script">
            </div>
            {/* [if lte IE 9]><![endif] */}
            {/*  Google Tag Manager (noscript)  */}
            <noscript>&lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NCJNF4D" height="0" width="0" style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;</noscript>
            {/*  End Google Tag Manager (noscript)  */}
          </div>
        </span>
      </span>
    )
  }


export default MapView

/* eslint-enable */